<template>
  <div class="report">
    <div class="report__container">
      <UiTitle :title="$t('report.title')" large centered />
      <UiSubtitle
        v-dompurify-html="
          $t('report.subtitle', {
            href,
          })
        "
        large
        centered
      >
      </UiSubtitle>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'Report',
  components: {
    UiTitle,
    UiSubtitle,
  },
  data: () => ({
    token: '',
    btoken: '',
  }),
  created() {
    this.$i18n.locale = navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 2)
    this.$vuetify.lang.current = this.$i18n.locale
  },
  async mounted() {
    this.token = this.$route.query.token
    this.btoken = this.$route.query.btoken
    try {
      await this.getFile({ token: this.token, btoken: this.btoken })
      window.location.href = this.href
    } catch {
      this.setAlert({
        color: 'error',
        text: this.$t('report.notification.error'),
      })
      this.$router.replace({ name: 'Error' })
    }
  },
  computed: {
    href() {
      if (this.token && this.btoken) {
        return `/api/ph/get_file.php?token=${this.token}&btoken=${this.btoken}`
      }
      return null
    },
  },
  methods: {
    ...mapActions({
      getFile: 'profilehub/getFile',
      setAlert: 'backoffice/setAlert',
    }),
  },
}
</script>

<style lang="scss">
.report {
  @include screen-flex;

  &__container {
    @include screen-container($padding: true, $h-center: true, $v-center: true);
  }
}
</style>
